import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { DataGrid } from "../../workflows/WorkflowsScope";
import { useDispatch, useSelector } from "react-redux";
import  { Row, Column } from "@harmony/enablers/components/data-grid/data-grid";
import { RequestStatus } from "../../../model/ServiceRequestStatus.model";
import { AppDispatch, RootState } from "../../../root-redux/RootState";
import { getAppPersonas_dev, getAppPersonas_uat, getAppPersonas_prod } from "../../../services/GetAppPersonaMapping.api";
import { getConnections_dev, getConnections_uat, getConnections_prod } from "../../../services/GetConnections.api";
import { getDevReleasesForConfiguration, getUatReleasesForConfiguration, getProdReleasesForConfiguration } from "../../../services/GetReleases.api";
import { withErrorBoundary } from "../../../shared-components/ErrorBoundary";
import { Button, Icon } from "../../spa-admin/SpaAdminScope";
import ScheduleWorkflow from "../../workflows/schedule-workflow/ScheduleWorkflow";
import { ReleaseCallsStatus } from "../ReleaseCallsStatus";
import { ReleasesHeader } from "../ReleasesHeader";
import { getConfigurationByIdandVersion_dev, getConfigurationByIdandVersion_prod, getConfigurationByIdandVersion_uat, getConfigurationSummaryByIdandVersion, getMultipleConfigurationsByIdandVersion } from "./ConfigurationApi";
import { ReleaseConfigurationActionsDEV } from "./ReleaseConfigurationActionsDEV";
import { ReleaseConfigurationActionsUAT } from "./ReleaseConfigurationActionsUAT";
import { ReleaseConfigurationActionsPROD } from "./ReleaseConfigurationActionsPROD";
import { NavMenuItemType } from "../../../model/NavMenu.model";
import { getCampaignById } from "../../../services/SPAAdmin/CampaignById.api";
import { setSelectedConfigurationVersions, setSelectedWorflowVersions, setShowAuditHistoryPanel } from "../ReleaseData.redux";
import { CampaignVersionSummary } from "../../spa-admin/campaign-version-summary/CampaignVersionSummary";
import { CampaignPreSummary } from "../../spa-admin/campaign-version-summary/CampaignPreSummary";
import { setPreSummary } from "../../spa-admin/redux/CampaignById.redux";
import { Environments, SPA_SubscriptionKey } from "../../spa-admin/SPAConstants";
import { getWorkflowByIdandVersion_dev, getWorkflowByIdandVersion_prod, getWorkflowByIdandVersion_uat, getWorkflowsBySubKeys } from "../../../services/GetWorkflows.api";
import { NonPropogateReleaseActionsUAT } from "../release-actions-comand-bar/NonPropogateReleaseActionsUAT";
import { Helper } from "../../spa-admin/utils/Help";
import { CampaignAuditHistory } from "../../spa-admin/campaign-version-summary/CampaignAuditHistory";

export interface ReleaseConfDataGridRow extends Row {
  id: string,
  cells: {
    field1: string;
    field2: string;
    field3: string;
    field4: string;
  };
};

export const releaseColumns: Column[] = [
  {
    field: "field1",
    content: "Version"
  },
  {
    field: "field2",
    content: "DEV Deployment"
  },
  {
    field: "field3",
    content: "UAT Deployment"
  },
  {
    field: "field4",
    content: "PROD Deployment"
  }
];

const ReleaseManagementForConfigurationC = () => {

    const location = useLocation();
    const dispatch = useDispatch<AppDispatch>();
    const { workflowId } = location.state;
    const { workflowName } = location.state;
    const {workflowType} = location.state;
    const { subscriptionKey } = location.state;
    //const { isCampaignRelease } = location.state;
    const [releaseRows, setReleaseRows] = useState([] as ReleaseConfDataGridRow[]);
    const [selectedVersion, setSelectedVersion] = useState('');
    const [releaseId, setReleaseId] = useState('');
    const [selectedReleaseIds, setSelectedReleaseIds] = useState([] as string[]);
    const getConfigStatus = useSelector((state: RootState) => state.releaseData.getConfigStatus);
    const getReleasesStatus = useSelector((state: RootState) => state.releases.getReleasesStatus);
    const userSalesRoles = useSelector((state: RootState) => state.userSalesRoles);
    const selectedConfigurationVersions = useSelector((state: RootState) => state.releaseData.selectedConfigurationVersions);
    const [loadingState, setLoadingState] = useState(true);   
    const [isPublished, setIsPublished] = useState(false);
    const [campaignDataset, setCampaginDataset] = useState([] as string[])  
    const selectedEnv = useSelector((state: RootState) =>state.env.selectedEnvItem );
    //const workflowsBySubkey = useSelector((state: RootState) => state.workflowsBySubKeys.list)
    let campDatasetWorkFlowId : any;  
   
    const navMenuItems = {
      Home:{"title":"Home","nav":NavMenuItemType.Home},
      Configuration:{"title":"Configuration","nav":NavMenuItemType.Configuration},
      Cancel:{"title":"Cancel","nav":NavMenuItemType.Configuration}
    }

    useEffect(() => {
      
      if (
        getConfigStatus.getWorkflowStatus.dev === RequestStatus.loading || getConfigStatus.getWorkflowStatus.uat === RequestStatus.loading || getConfigStatus.getWorkflowStatus.prod === RequestStatus.loading || 
        getConfigStatus.getConnectionsStatus.dev === RequestStatus.loading || getConfigStatus.getConnectionsStatus.uat === RequestStatus.loading || getConfigStatus.getConnectionsStatus.prod === RequestStatus.loading || 
        getConfigStatus.getAppPersonaStatus.dev === RequestStatus.loading ||  getConfigStatus.getAppPersonaStatus.uat === RequestStatus.loading ||  getConfigStatus.getAppPersonaStatus.prod === RequestStatus.loading || 
        getConfigStatus.getModelMappingStatus.dev === RequestStatus.loading || getConfigStatus.getModelMappingStatus.uat === RequestStatus.loading ||
        getConfigStatus.getMergeRulesStatus.dev === RequestStatus.loading || getConfigStatus.getMergeRulesStatus.uat === RequestStatus.loading ||
        getReleasesStatus.dev === RequestStatus.loading || getReleasesStatus.uat === RequestStatus.loading || getReleasesStatus.prod === RequestStatus.loading ||
        getConfigStatus.getApiConfigStatus.dev === RequestStatus.loading || getConfigStatus.getApiConfigStatus.uat === RequestStatus.loading ||
        getConfigStatus.getCatalogueConfigStatus.dev === RequestStatus.loading || getConfigStatus.getCatalogueConfigStatus.uat === RequestStatus.loading
        ) 
        setLoadingState(true);
      else
          setLoadingState(false);
      
    }, [getConfigStatus, getReleasesStatus]);

    useEffect(() => {
        dispatch(getDevReleasesForConfiguration({configurationId:workflowId, configurationType: workflowType}));
        dispatch(getUatReleasesForConfiguration({configurationId:workflowId, configurationType: workflowType}));
        dispatch(getProdReleasesForConfiguration({configurationId:workflowId, configurationType: workflowType}));

        //dispatch(getUatReleasesByEntityType({workflowId:workflowId, workflowType: SPA_WORKFLOW_TYPE}));
        //dispatch(getProdReleasesByEntityType({workflowId:workflowId, workflowType: SPA_WORKFLOW_TYPE}));
      
      dispatch(getAppPersonas_dev());
      dispatch(getAppPersonas_uat());
      dispatch(getAppPersonas_prod());
    }, [dispatch]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    let releaseItems:{
      version: string, releaseId:string, workflowId:string, id_dev:string, id_uat:string, id_prod:string, time_dev:string, time_uat:string, time_prod:string, dev:boolean, uat:boolean, prod:boolean,
      status_dev: string, status_uat: string, status_prod:string
      }[] = [];

      
    //= workflowsBySubkey.filter(x => { return x.tenantName === "Solution Play Accelerator(SPA)" && x.workFlowType === "Dataset" && x.status === "Published"}).map(x => x.config.workflowId);

    const devReleases = useSelector((state: RootState) => state.releases.releasesDev);
    const uatReleases = useSelector((state: RootState) => state.releases.releasesUat);
    const prodReleases = useSelector((state: RootState) => state.releases.releasesProd);
    
    
    useEffect(() => {
        if(workflowType === "Dataset")
        {
          dispatch(getWorkflowByIdandVersion_dev({workflowId:workflowId, version: selectedVersion.length != 0 ? selectedVersion : devReleases.length != 0 ? devReleases[0].version : ''}));
          dispatch(getWorkflowByIdandVersion_uat({workflowId:workflowId, version: selectedVersion.length != 0 ? selectedVersion : uatReleases.length != 0 ? uatReleases[0].version : ''}));
          dispatch(getWorkflowByIdandVersion_prod({workflowId:workflowId, version: selectedVersion.length != 0 ? selectedVersion : prodReleases.length != 0 ? prodReleases[0].version : ''}));  
        }
        else
        {
          dispatch(getConfigurationByIdandVersion_dev({id:workflowId, version: selectedVersion.length != 0 ? selectedVersion : devReleases.length != 0 ? devReleases[0].version : '1',type:workflowType})).then((res: any) => {
            if (res.payload.dataSets !== undefined) {
              setCampaginDataset(res.payload.dataSets)
            }
            ;
          });
          dispatch(getConfigurationByIdandVersion_uat({id:workflowId, version: selectedVersion.length != 0 ? selectedVersion : uatReleases.length != 0 ? uatReleases[0].version : '1',type:workflowType})).then((res:any) => {
            if (res.payload.dataSets !== undefined) {
              setCampaginDataset(res.payload.dataSets)
            }
          });
          dispatch(getConfigurationByIdandVersion_prod({id:workflowId, version: selectedVersion.length != 0 ? selectedVersion : prodReleases.length != 0 ? prodReleases[0].version : '1',type:workflowType})).then((res:any) => {
            if (res.payload.dataSets !== undefined) {
              setCampaginDataset(res.payload.dataSets)
            }
          });
        }
      }, [dispatch, selectedVersion, workflowId]);

    useEffect(() => {
          const subscriptionKeys = [];
          subscriptionKeys.push(subscriptionKey);
          dispatch(getConnections_dev({subscriptionKeys}));
          dispatch(getConnections_uat({subscriptionKeys}));
          dispatch(getConnections_prod({subscriptionKeys}));
  }, [dispatch, subscriptionKey]);

    
    let devConfiguration = useSelector((state: RootState) => state.releaseData.devConfiguration);
    let uatConfiguration = useSelector((state: RootState) => state.releaseData.uatConfiguration);
    let prodConfiguration = useSelector((state: RootState) => state.releaseData.prodConfiguration);    
    
        useEffect(() => {
          releaseItems = [];
            if(devReleases.length > 0){
            devReleases?.map((release) => {

              let uat_ind = uatReleases.length == 0 ? -1 : uatReleases.findIndex(item  => item.version == release.version);
              let prod_ind = prodReleases.length == 0 ? -1 : prodReleases.findIndex(item  => item.version == release.version);

              if(releaseItems.findIndex(item => item.version == release.version) == -1)
              {
                
                releaseItems.push({
                  version: release.version,
                  releaseId: release.releaseId,
                  workflowId: release.workflowId,
                  id_dev: release?.id,
                  id_uat: uat_ind > -1 ? uatReleases[uat_ind]?.id : "",
                  id_prod: prod_ind > -1 ? prodReleases[prod_ind]?.id : "",
                  time_dev: release.timestamp,
                  time_uat: uat_ind > -1 ? uatReleases[uat_ind].timestamp : "",
                  time_prod: prod_ind > -1 ? prodReleases[prod_ind].timestamp : "",
                  dev: true,
                  uat: uat_ind > -1 ? true : false,
                  prod: prod_ind > -1 ? true : false,
                  status_dev: release.status,
                  status_uat: uat_ind > -1 ? uatReleases[uat_ind].status : "",
                  status_prod: prod_ind > -1 ? prodReleases[prod_ind].status : ""
                  })
                }});
            }
            else if(uatReleases.length > 0){
              uatReleases?.map((release) => {

                let prod_ind = prodReleases.length == 0 ? -1 : prodReleases.findIndex(item  => item.version == release.version);
  
                if(releaseItems.findIndex(item => item.version == release.version) == -1)
                {
                  
                  releaseItems.push({
                    version: release.version,
                    releaseId: release.releaseId,
                    workflowId: release.workflowId,
                    id_dev: "",
                    id_uat: release?.id,
                    id_prod: prod_ind > -1 ? prodReleases[prod_ind]?.id : "",
                    time_dev: "",
                    time_uat: release.timestamp,
                    time_prod: prod_ind > -1 ? prodReleases[prod_ind].timestamp : "",
                    dev: true,
                    uat: true,
                    prod: prod_ind > -1 ? true : false,
                    status_dev: "",
                    status_uat: release.status,
                    status_prod: prod_ind > -1 ? prodReleases[prod_ind].status : ""
                    })
                  }});
            }
              
               
          }, [devReleases, uatReleases, prodReleases]);

        useEffect(() => {

          const transformedReleaseRows : ReleaseConfDataGridRow[] = [];

          releaseItems.map((item:any) => {

            transformedReleaseRows.push({
              id: item.version,
              cells:{
                field1: item.version,
                field2: item.status_dev == "Published" ? "Deployed" : "Draft",
                field3: item.uat == true ?  (item.status_uat == "Submitted" ? "Awaiting Approval" : item.status_uat == "Published" ? "Deployed" : "Draft") : "",
                field4: item.prod == true ? (item.status_prod == "Submitted" ? "Awaiting Approval" : item.status_prod == "Published" ? "Deployed"  : "Draft") : "",
              }
            });
            setReleaseRows(transformedReleaseRows);            
          });

        }, [releaseItems]);

        useEffect(() => {
        const devStatus = releaseRows.find((x:any) => x.cells.field1 == selectedVersion)?.cells.field2;
        const uatStatus = releaseRows.find((x:any) => x.cells.field1 == selectedVersion)?.cells.field4;
        const isDevDeployed = devStatus == "Deployed" ? true : false;
        const isUatDeployed = uatStatus == "Deployed" ? true : false;
        setIsPublished(isDevDeployed ||isUatDeployed ? true : false);

        },[releaseRows,selectedVersion]);
       
        const handleOnAuditCLick = (version:string):void => {
          let versionNumber = parseInt(version);
          dispatch(setShowAuditHistoryPanel(true));
          dispatch(getMultipleConfigurationsByIdandVersion({id: workflowId, versions: [version,(versionNumber-1).toString()], type: workflowType}));
        };
        
        const handleOnCompareClick = ():void => {
          dispatch(setShowAuditHistoryPanel(true));
          dispatch(getMultipleConfigurationsByIdandVersion({id: workflowId, versions: selectedConfigurationVersions, type: workflowType}))
        };
        const handleOnPreSummaryClick = ():void => {
          dispatch(getCampaignById(workflowId)).unwrap().then((result) => {
            dispatch(setPreSummary(true));
          });
        };
        const handleRowChange = (e:any) => {
          let selectedRows : string[] = [];
          selectedRows = releaseRows?.filter(x => x.selected)?.map(x => {
              return x.id.toString();
          });
          setSelectedReleaseIds(selectedRows);
          if(selectedRows.length === 1)
          {
            setSelectedVersion(releaseRows?.filter(x => x.selected)[0].cells.field1);
            const releaseId = devReleases.find(x => x?.version == e.id)?.releaseId;
            setReleaseId(releaseId!);
          }
          else{
            setSelectedVersion('');
            setReleaseId('');  
          }
          const selectedVersions = releaseRows?.filter(x => x.selected)?.map(x => {
            return x.cells.field1.toString();
          })
          if(workflowType === "Dataset")
            dispatch(setSelectedWorflowVersions(selectedVersions));
          else
            dispatch(setSelectedConfigurationVersions(selectedVersions));
      };

  return (
    <>

    <ReleasesHeader workflowName = {workflowName}  navMenuItems={navMenuItems}/>
      
      <div>
        <div style={{ marginLeft: "8px" }}>
          <div
            style={{ display: "flex", marginTop: "36px", alignItems: "center" }}
          >
            {!userSalesRoles.isSpaUser ? (
                <>
                        <>
                            <ReleaseConfigurationActionsDEV
                                selectedReleaseIds={selectedReleaseIds}
                                id={workflowId}
                                type={workflowType}
                                devConfiguration={devConfiguration}
                                selectedVersion={selectedVersion}
                                releaseRows={releaseRows}
                                subscriptionKey={subscriptionKey}
                                setCampaginDataset={campaignDataset}
                            />
                        </>
                    <span style={{ fontSize: "28px", fontWeight: "300" }}>|</span>
                </>
            ) : (
                <></>
            )}
            
            <>
                {
                  Helper.isEmpty(devConfiguration) ?
                  <NonPropogateReleaseActionsUAT
                    selectedReleaseIds = {selectedReleaseIds}
                    workflowId={workflowId}
                    type={workflowType}
                    workflowName = {workflowName}
                    uatConfiguration={uatConfiguration}
                    selectedVersion={selectedVersion}
                    subscriptionKey={subscriptionKey}
                    releaseId={releaseId}
                    releaseRows={releaseRows}
                    setCampaginDataset={campaignDataset}
                  />
                  :
                  <ReleaseConfigurationActionsUAT
                    selectedReleaseIds = {selectedReleaseIds}
                    id={workflowId}
                    type={workflowType}
                    workflowName = {workflowName}
                    devConfiguration={devConfiguration}
                    uatConfiguration={uatConfiguration}
                    selectedVersion={selectedVersion}
                    subscriptionKey={subscriptionKey}
                    releaseId={releaseId}
                    releaseRows={releaseRows}
                    setCampaginDataset={campaignDataset}
                  />
                }
            </>
            <span style={{ fontSize: "28px", fontWeight: "300" }}>|</span>
            
            <>
              <ReleaseConfigurationActionsPROD
                selectedReleaseIds = {selectedReleaseIds}
                id={workflowId}
                type={workflowType}
                workflowName = {workflowName}
                prodConfiguration = {prodConfiguration}
                uatConfiguration={uatConfiguration}
                selectedVersion={selectedVersion}
                subscriptionKey={subscriptionKey}
                releaseId={releaseId}
                releaseRows={releaseRows}
                setCampaginDataset={campaignDataset}
              />
            </>

            <>
              <span style={{ fontSize: "28px", fontWeight: "300" }}>|</span>
              <Button
                disabled={selectedReleaseIds.length !== 2}
                onClick={() => {handleOnCompareClick()}}
                appearance="command"
                style={{ fontSize: "14px" }}
              >
                <Icon slot="start" name="compare" />
                Compare
              </Button>
              {isPublished && selectedEnv!==Environments.PROD &&  <Button
              disabled={selectedReleaseIds.length !== 1}
              onClick={() => {handleOnPreSummaryClick()}}
              appearance="command"
              style={{ fontSize: "14px" }}
            >
              <Icon slot="start" name="boardactivityfeed" />
              Pre Summary
            </Button>
          }
              <Button
                disabled={selectedReleaseIds.length !== 1}
                onClick={() => dispatch(getConfigurationSummaryByIdandVersion({id: workflowId, version: selectedVersion, type: workflowType}))}
                appearance="command"
                style={{ fontSize: "14px" }}
              >
                <Icon slot="start" name="boardactivityfeed" />
                Summary
              </Button>
            </>
            {/* <RollBack
            selectedReleaseIds = {selectedReleaseIds}
              workflowId={workflowId}
              latestVersion={devReleases[0]?.version}
              selectedVersion={selectedVersion}
            />
            <Button
              onClick={() => dispatch(setShowSchedulePanel(true))}
              appearance="command"
              style={{ fontSize: "14px" }}
            >
              <Icon slot="start" name="timerart64" />
              Schedule
            </Button> */}
          </div>
          <hr />
        </div>
        {
          loadingState ? 
          <ReleaseCallsStatus/> 
          :
        <DataGrid
          style={{
            height: "calc(100vh - 270px)",
            marginLeft: "8px",
            position: "relative",
            overflowY: "hidden",
          }}
          fixedHeading={true}
          rows={releaseRows}
          columns={userSalesRoles.isSpaUser ? releaseColumns.filter(c => !c.content.startsWith('DEV')) : releaseColumns}
          select="multiple"
          onHeRowSelectChange={(e) => {
            handleRowChange(e.detail)
          }}
          onHeSelectAllChange={(e) => {
            handleRowChange(e.detail)
          }}
        >
          {
            releaseRows.map((row) => {
              return <>
              {
                row.cells.field1 && parseInt(row.cells.field1) >1 ?
                <Button slot={`field1-${row.cells.field1}`} appearance="link" onClick={() => {
                  handleOnAuditCLick(row.cells["field1"]);
                }}>
                  {row.cells["field1"]}
                </Button>
                :
                <span slot={`field1-${row.cells.field1}`}>
                  {row.cells["field1"]}
                </span>
              }
              </>
            })
          }
        </DataGrid>
}
      </div>
      <ScheduleWorkflow workflowId={workflowId} showEnv={true} />
      {/* <DiffPanelCampaign /> */}
      <CampaignVersionSummary releaseRows={releaseRows}
                    selectedVersion={selectedVersion}
                    />
       
      <CampaignAuditHistory/>
      <CampaignPreSummary />
    
    </>
  );
};

const ReleaseManagementForConfiguration= withErrorBoundary("release management main", ReleaseManagementForConfigurationC);
export default ReleaseManagementForConfiguration;
