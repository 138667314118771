import { CommandBar, Button, Icon, Dropdown, Menu, MenuItem } from '../../workflows/WorkflowsScope';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../root-redux/RootState';
import { setWorkflowSummaryStatus } from '../../workflows/workflows-redux/Workflows.redux';
import { postWorkflow, postWorkflowReleaseManagement } from '../../../services/PostWorkflow.api';
import { addConnection, patchApiConfig, patchCatalogueConfig, patchMergeRule, patchModelMapping, patchReleaseConfiguration, patchReleaseWorkflow, setReleaseConfigurationStatus, setReleaseWorkflowStatus } from '../ReleaseData.redux';
import { useEffect, useState } from 'react';
import { addRelease, setReleaseStatus } from '../Releases.redux';
import { ConnectionsCollection } from '../../../model/connections/ConnectionsCollection.model';
import { propagateConnectionReleaseManagement } from '../../../services/PropagateConnections.api';
import { Environment, Release } from '../../../model/releases/Release.model';
import { v4 as uuidv4 } from 'uuid';
import { publishWorkflow, publishWorkflowReleaseManagement } from '../../../services/PublishWorkflow.api';
import { unwrapResult } from '@reduxjs/toolkit';
import { uatReleasePermissionsSelector } from '../ReleaseRBAC.selectors.redux';
import { SuccessNotification } from '../../notifications/SuccessNotification';
import { ErrorNotification } from '../../notifications/ErrorNotification';
import { postInsightFamilyReleaseManagement } from '../../../services/PostInsightFamily.api';
import { withErrorBoundary } from '../../../shared-components/ErrorBoundary';
import { getModelMapping_dev } from '../../../services/GetModelMapping.api';
import { Schedule } from '../../../model/workflows/Schedule.model';
import { postReleaseReleaseManagement } from '../../../services/PostRelease.api';
import { ModelMapping } from '../../../model/configModels/ModelMapping.model';
import { updateModelMapping_uat } from '../../../services/PostModelMapping.api';
import { TypeMappingObject } from '../../../model/configModels/TypeMappingObject.model';
import { updateTypeMapping_uat } from '../../../services/PostTypeMapping.api';
import { validateInsightFamily_uat } from '../../../services/ValidateInsightFamily.api';
import { getApiConfig_dev } from '../../../services/APIConfig/GetApiConig.api';
import { propagateRoute } from '../../../services/APIConfig/PropagateApiConfig.api';
import { EdmEntityTypeSettings } from '../../../model/apiConfig/EdmEntityTypeSettings.model';
import { MergeRule } from '../../../model/merge-rules/MergeRule.model';
import { postConfigurationDetailsForRelease, publishConfigurationReleaseManagement } from './ConfigurationApi';
import { getWorkflowsBySubKeys } from "../../../services/GetWorkflows.api";
import { SPA_SubscriptionKey } from "../../spa-admin/SPAConstants";
import { Helper } from "../../spa-admin/utils/Help";

export const handlePostRelease = (props:any) => {

  const environments: Environment[] = [
    { environment: "dev", isDeployed: false },
    { environment: "uat", isDeployed: true },
    { environment: "prod", isDeployed: false },
  ];

  const releaseData: Release = {
    environments: environments,
    timestamp: "",
    releaseId: props.releaseId,
    workflowId: props.id,
    version: props.version,
    id: uuidv4().toString(),
    type: "Propagate",
    status: "Published",
    scheduleCreated: false
  };
  
  return releaseData;
};

export const handlePropagateConnections = (props:any) => {
  //here dev means lower environment and uat means higher environment
  const devConnections = props.devConnections;
  const uatConnections = props.uatConnections;
  const devConfiguration = props.devConfiguration;
  let connData : {type: string, id:string}[] = [];

  let dataIngestion = devConfiguration.dataIngestion;
  dataIngestion?.map((item: any) => {
    if (connData.findIndex((data) => data.id == item.connectionId) == -1) {
      connData.push({
        type: item.type,
        id: item.connectionId,
      });
    }
  });

  let delta = devConfiguration.delta;
  delta?.map((item:any) => {
    if (connData.findIndex((data) => data.id == item.connectionId) == -1) {
      connData.push({
        type: "BLOB",
        id: item.connectionId,
      });
    }
  });

  let publish = devConfiguration.publish;
    publish?.map((item:any) => {
      if(item.type != "SQL")
      {
        if (connData.findIndex((data) => data.id == item.connectionId) == -1) {
          connData.push({ type: item.type, id: item.connectionId });
        }
        if (connData.findIndex((data) => data.id == item.blobConnectionId) == -1) {
          connData.push({ type: "BLOB", id: item.blobConnectionId });
        }
      }
      else{
        if (connData.findIndex((data) => data.id == item.connectionId) == -1) {
          connData.push({ type: "BLOB",id: item.connectionId });
        }
      }
    });

    let connectionItemsUatFinal = {} as ConnectionsCollection

    Object.keys(devConnections).forEach((key: string) => {

      let connectionItemsDev = devConnections[key];
      let connectionItemsUat = uatConnections[key];
     
      connData.map((connDataItem) => {

        if(connectionItemsDev && connDataItem.id.length != 0 && connDataItem.type.length != 0)
        {
           if(connDataItem.type.toUpperCase() == "ADLS" || connDataItem.type.toUpperCase() == "ADLSGEN1")
        {
          const devData = connectionItemsDev.adlsConnections.find((data:any)=> data.connectionId == connDataItem?.id);
          const uatIndex = connectionItemsUat != null || connectionItemsUat != undefined ? connectionItemsUat.adlsConnections.findIndex((data:any)=> data.connectionId == connDataItem?.id) : -1

            if(uatIndex > -1 && devData)
            {
                let updatedAdlsConn = [...connectionItemsUat.adlsConnections];
                updatedAdlsConn[uatIndex] = devData;
                connectionItemsUat = {...connectionItemsUat, adlsConnections: updatedAdlsConn};
            }
            else if (devData)
            { 
              if(connectionItemsUat != undefined )
                {
                  const totalConnections = connectionItemsUat.adlsConnections.length;
                  let updatedAdlsConn = [...connectionItemsUat.adlsConnections];
                  updatedAdlsConn[totalConnections] = devData;
                  connectionItemsUat = {...connectionItemsUat, adlsConnections: updatedAdlsConn};
                }
            }
        }
      }
      if(connDataItem.type.toUpperCase() == "BLOB")
      {
        const devData = connectionItemsDev.blobConnections.find((data:any)=> data.connectionId == connDataItem?.id);
        const uatIndex = connectionItemsUat != null || connectionItemsUat != undefined ? connectionItemsUat.blobConnections.findIndex((data:any)=> data.connectionId == connDataItem?.id) : -1

          if(uatIndex > -1 && devData)
          {
            
              let updatedBlobConn = [...connectionItemsUat.blobConnections];
              updatedBlobConn[uatIndex] = devData;
              connectionItemsUat = {...connectionItemsUat, blobConnections: updatedBlobConn};
          }
          else if (devData)
          {
            if(connectionItemsUat != undefined)
            {
              const totalConnections = connectionItemsUat.blobConnections.length;
              let updatedBlobConn = [...connectionItemsUat.blobConnections];
              updatedBlobConn[totalConnections] = devData;
              connectionItemsUat = {...connectionItemsUat, blobConnections: updatedBlobConn};
            }
           
          }
      }


      if(connDataItem.type.toUpperCase() == "COSMOS")
      {
        const devData = connectionItemsDev.cosmosConnections.find((data:any)=> data.connectionId == connDataItem?.id);
        const uatIndex = connectionItemsUat != null || connectionItemsUat != undefined? connectionItemsUat.cosmosConnections.findIndex((data:any)=> data.connectionId == connDataItem?.id) : -1

          if(uatIndex > -1 && devData)
          {
              let updatedCosmosConn = [...connectionItemsUat.cosmosConnections];
              updatedCosmosConn[uatIndex] = devData;
              connectionItemsUat = {...connectionItemsUat, cosmosConnections: updatedCosmosConn};
          }
          else if (devData)
          {
            if(connectionItemsUat != undefined)
            {
              const totalConnections = connectionItemsUat.cosmosConnections.length;
              let updatedCosmosConn = [...connectionItemsUat.cosmosConnections];
              updatedCosmosConn[totalConnections] = devData;
              connectionItemsUat = {...connectionItemsUat, cosmosConnections: updatedCosmosConn};
            }
          }
      }

      if(connDataItem.type.toUpperCase() == "API")
      {
        const devData = connectionItemsDev.apiConnections.find((data:any)=> data.connectionId == connDataItem?.id);
        const uatIndex = connectionItemsUat != null || connectionItemsUat != undefined ? connectionItemsUat.apiConnections.findIndex((data:any)=> data.connectionId == connDataItem?.id) : -1

          if(uatIndex > -1 && devData)
          {
              let updatedApiConn = [...connectionItemsUat.apiConnections];
              updatedApiConn[uatIndex] = devData;
              connectionItemsUat = {...connectionItemsUat, apiConnections: updatedApiConn};
          }
          else if (devData)
          {
            if(connectionItemsUat != undefined)
            {
              const totalConnections = connectionItemsUat.apiConnections.length;
              let updatedApiConn = [...connectionItemsUat.apiConnections];
              updatedApiConn[totalConnections] = devData;
              connectionItemsUat = {...connectionItemsUat, apiConnections: updatedApiConn};
            }
          }
      }

      if(connDataItem.type.toUpperCase() == "SQL")
      {
        const devData = connectionItemsDev.sqlConnections.find((data:any)=> data.connectionId == connDataItem?.id);
        const uatIndex = connectionItemsUat != null || connectionItemsUat != undefined ? connectionItemsUat.sqlConnections.findIndex((data:any)=> data.connectionId == connDataItem?.id) : -1

          if(uatIndex > -1 && devData)
          {
              let updatedSqlConn = [...connectionItemsUat.sqlConnections];
              updatedSqlConn[uatIndex] = devData;
              connectionItemsUat = {...connectionItemsUat, sqlConnections: updatedSqlConn};
          }
          else if (devData)
          {
            if(connectionItemsUat != undefined)
            {
              const totalConnections = connectionItemsUat.sqlConnections.length;
              let updatedSqlConn = [...connectionItemsUat.sqlConnections];
              updatedSqlConn[totalConnections] = devData;
              connectionItemsUat = {...connectionItemsUat, sqlConnections: updatedSqlConn};
            }
          }
      }

      if(connDataItem.type.toUpperCase() == "SQLAAD")
      {
        const devData = connectionItemsDev.sqlaadConnections.find((data:any)=> data.connectionId == connDataItem?.id);
        const uatIndex = connectionItemsUat != null || connectionItemsUat != undefined ? connectionItemsUat.sqlaadConnections.findIndex((data:any)=> data.connectionId == connDataItem?.id) : -1

          if(uatIndex > -1 && devData)
          {
              let updatedSqlaadConn = [...connectionItemsUat.sqlaadConnections];
              updatedSqlaadConn[uatIndex] = devData;
              connectionItemsUat = {...connectionItemsUat, sqlaadConnections: updatedSqlaadConn};
          }
          else if (devData)
          {
            if(connectionItemsUat != undefined)
            {
              const totalConnections = connectionItemsUat.sqlaadConnections.length;
              let updatedSqlaadConn = [...connectionItemsUat.sqlaadConnections];
              updatedSqlaadConn[totalConnections] = devData;
              connectionItemsUat = {...connectionItemsUat, sqlaadConnections: updatedSqlaadConn};
            }
          }
      }

      if(connDataItem.type.toUpperCase() == "TOPIC")
      {
        const devData = connectionItemsDev.topicConnections.find((data:any)=> data.connectionId == connDataItem?.id);
        const uatIndex = connectionItemsUat != null || connectionItemsUat != undefined ? connectionItemsUat.topicConnections.findIndex((data:any)=> data.connectionId == connDataItem?.id) : -1

          if(uatIndex > -1 && devData)
          {
              let updatedTopicConn = [...connectionItemsUat.topicConnections];
              updatedTopicConn[uatIndex] = devData;
              connectionItemsUat = {...connectionItemsUat, topicConnections: updatedTopicConn};
          }
          else if (devData)
          {
            if(connectionItemsUat != undefined )
            {
              const totalConnections = connectionItemsUat.topicConnections.length;
              let updatedTopicConn = [...connectionItemsUat.topicConnections];
              updatedTopicConn[totalConnections] = devData;
              connectionItemsUat = {...connectionItemsUat, topicConnections: updatedTopicConn};
            }
          }
      }

      if(connDataItem.type.toUpperCase() == "EVENT")
      {
        const devData = connectionItemsDev.eventConnections.find((data:any)=> data.connectionId == connDataItem?.id);
        const uatIndex = connectionItemsUat != null || connectionItemsUat != undefined ? connectionItemsUat.eventConnections.findIndex((data:any)=> data.connectionId == connDataItem?.id) : -1

          if(uatIndex > -1 && devData)
          {
              let updatedEventConn = [...connectionItemsUat.eventConnections];
              updatedEventConn[uatIndex] = devData;
              connectionItemsUat = {...connectionItemsUat, eventConnections: updatedEventConn};
          }
          else if (devData)
          {
            if(connectionItemsUat != undefined )
            {
              const totalConnections = connectionItemsUat.eventConnections.length;
              let updatedEventConn = [...connectionItemsUat.eventConnections];
              updatedEventConn[totalConnections] = devData;
              connectionItemsUat = {...connectionItemsUat, eventConnections: updatedEventConn};
            }
          }
      }

      connectionItemsUatFinal = connectionItemsUat;

      });
   });
   return connectionItemsUatFinal;

};





const ReleaseConfigurationActionsUATC: React.FC<any> = (props: any) => {

  const userId = useSelector((state: RootState) => state.userDetails.localAccountId);
  const isCampaignRelease = true;//props.isCampaignRelease;
  const dispatch = useDispatch();
  const dispatchApp = useDispatch<AppDispatch>();
  const devConnections = useSelector((state: RootState) => state.releaseData.devConnections );
  const uatConnections = useSelector((state: RootState) => state.releaseData.uatConnections );
  const uatAppPersona = useSelector((state: RootState) => state.releaseData.uatAppPersonas) ;
  const catalogueConfig_dev = useSelector((state: RootState) => state.releaseData.catalogueConfig.devCatalogueConfig);
  //const devWorkflow = props.devWorkflow;
  const actionedVersion = props.selectedVersion;
  const subscriptionKey = props.subscriptionKey;
  const workflowId = props.id;
  const workFlowType = props.type;
  const releaseId = props.releaseId;
  const selectedReleaseIds = props.selectedReleaseIds;
  const [uatApproveReject, setuatApproveReject] = useState(true);
  const [uatDeploy, setuatDeploy] = useState(true);
  const releaseRows = props.releaseRows;
  const [showErrorNotif, setShowErrorNotif] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [successText, setSuccessText] = useState("");
  const [showSuccessNotif, setShowSuccessNotif] = useState(false);
  const [workflowsBySubkey, setworkflowsBySubkey] = useState([] as string[]);
  const campaignDataset = props.setCampaginDataset;
  
  const workflowName = props.workflowName; 

  const releasePermUat = useSelector(uatReleasePermissionsSelector).release;
  const approvePermUat = useSelector(uatReleasePermissionsSelector).approve;
  const superAdmin = useSelector((state: RootState) => state.isSuperAdmin.isSuperAdmin);

  const devConfiguration = props.devConfiguration;
  const uatConfiguration = props.uatConfiguration;

  useEffect(() => {


    const devStatus = releaseRows.find((x:any) => x.cells.field1 == actionedVersion)?.cells.field2;
    const uatStatus = releaseRows.find((x:any) => x.cells.field1 == actionedVersion)?.cells.field4;
    const uatApprove = releaseRows.find((x:any) => x.id == actionedVersion)?.cells.field4;
    
    setuatDeploy(actionedVersion ? (devStatus == "Deployed" && (uatStatus == "Draft" || uatStatus == "") ? false : true) : true);
    setuatApproveReject(uatApprove == "Awaiting Approval" ? false : true);

  }, [actionedVersion, releaseRows]);

  useEffect(() => {
    dispatchApp(getWorkflowsBySubKeys({subKeys: SPA_SubscriptionKey})).then((response: any) => {
      const subKey = response.payload.filter((x: any) => { return x.tenantName === "Solution Play Accelerator(SPA)" && x.workFlowType === "Dataset" && x.status === "Published"}).map((x:any) => x.config.workflowId);
      setworkflowsBySubkey(subKey);
    })
  }, []);
 
  // useEffect(()=>{
  //   if(devWorkflow && devWorkflow.workFlowType){
  //   setinsightWorkflow(devWorkflow.workFlowType);
  //   setfamilyName(devWorkflow.family);
  //   if (devWorkflow.workFlowType === "Insight")
  //   {
  //     setRouteName(devWorkflow.family);
  //   }
  //   else {
  //      {
  //       for(var element of uatAppPersona) {
  //       if (element.subscriptionKey === subscriptionKey)
  //       {
  //         var routeName = element.appName.replace(/\s+/g, '').toLowerCase()
  //         setRouteName(routeName);
  //         break;
  //       }
  //     }
  //     };
  //   }
  // }
  // }, [devWorkflow]);

 
  // useEffect(() => {
  //   dispatchApp(getModelMapping_dev({ name: "", id: "" }));
  //   dispatchApp(getMergeRules_dev(""));
  //   if (devWorkflow.workFlowType === "Insight")
  //   {
  //       var insightId = getInsightId(devWorkflow)

  //     dispatchApp(getApiConfig_dev({routeType: "Insight", subsKey: subscriptionKey}));

  //   if (insightId)
  //   dispatchApp(getCatalogueConfig_dev(insightId))
  //   //if (devWorkflow.typeOfMerge != 'SkipMerge') 
  //   // dispatchApp(getApiConfig_dev({routeType: "Recommendation", subsKey: subscriptionKey}));
  //   }
  //   else if (devWorkflow.workFlowType === "Recommendation") {
  //   dispatchApp(getApiConfig_dev({routeType: "Recommendation", subsKey: subscriptionKey}));
  //   }
  //   else{
  //     dispatchApp(getApiConfig_dev({routeType: "Dataset", subsKey: subscriptionKey}));
  //     }
  // }, [dispatchApp, actionedVersion, workflowId]);


  return (
    <div 
    style={{display:"flex", border:"none", outline:"none", boxShadow:"none", padding:"4px", fontSize:"14px", alignItems:"center", marginRight:"12px", marginLeft:"12px"}}>

      <div> <SuccessNotification successNotif={showSuccessNotif} setsuccesnotif={setShowSuccessNotif} successText = {successText} /> </div>
      <div> <ErrorNotification errorNotif={showErrorNotif} seterrornotif={setShowErrorNotif} errorText = {errorText} /> </div>

      <div>
      <span style={{fontWeight:"700", marginRight:"8px"}}>UAT</span>
      </div>

      <div>
      <CommandBar>
      <Button appearance="command" disabled={ uatDeploy || selectedReleaseIds.length != 1}
      onClick={ async() =>
        {
          if (releasePermUat.includes(subscriptionKey) || superAdmin) {
            if (!Helper.isDatasetPublished(campaignDataset, workflowsBySubkey)) {
              setErrorText("SPA/ Sales Campaign cannot be published without an associated valid dataset (Valid dataset must have atleast 1 record and must be published.");
              setShowErrorNotif(true);
          } else {
              const updatedConfiguration = {...devConfiguration,postDeployInfo:[],preSummary:null,
              status:"Submitted", environment: "uat" };
              dispatchApp(postConfigurationDetailsForRelease({...updatedConfiguration,env:"uat",type:workFlowType}));
                
              const propagateRelease = handlePostRelease({id: workflowId , version : actionedVersion , releaseId : releaseId });
              const updatedPropagateRelease = {...propagateRelease, status:"Submitted", environment: "uat" }
              dispatchApp(postReleaseReleaseManagement({payload: updatedPropagateRelease, env:"uat"}));
              dispatch(addRelease({input: updatedPropagateRelease, env:"uat"}));
              dispatch(setReleaseStatus({version: actionedVersion, status: "Submitted", env: "uat"}));

              const propagateConnections =  handlePropagateConnections( {devConfiguration : props.devConfiguration, devConnections : devConnections, uatConnections:uatConnections} )
              if(propagateConnections)
              {
                dispatchApp(propagateConnectionReleaseManagement({payload:propagateConnections, env:"uat", subscriptionKey: subscriptionKey}));
                dispatch(addConnection({subskey: subscriptionKey, input: propagateConnections, env:"uat"}));
              }
              setuatDeploy(true);
              setShowSuccessNotif(true); setSuccessText("Submitted for Approval");
            }
          }
          else
          {
            setErrorText("You do not have permission to deploy this workflow"); setShowErrorNotif(true);
          }
        }
      }>
        <Icon slot="start" name="rocket"></Icon>
        Deploy
      </Button>
      
      <Button appearance="command" 
      disabled = {uatApproveReject || selectedReleaseIds.length != 1} 
      onClick= { async() => 
       { 
        if(approvePermUat.includes(subscriptionKey) || superAdmin)
        { 
          if (!Helper.isDatasetPublished(campaignDataset, workflowsBySubkey)) {
            setErrorText("SPA/ Sales Campaign cannot be published without an associated valid dataset (Valid dataset must have atleast 1 record and must be published.");
            setShowErrorNotif(true);
        } else {
          const hasApprovedList = [];
          hasApprovedList.push(userId);
          const updatedEntity = {...devConfiguration, hasApproved:hasApprovedList, environment: "uat", schedule: {} as Schedule };
          dispatch(patchReleaseConfiguration({input: updatedEntity, env: "uat"}));
          dispatch(setReleaseStatus({version: actionedVersion, status: "Published", env: "uat"}));
          const propagateRelease = handlePostRelease({id: workflowId , version : actionedVersion , releaseId : releaseId });
          dispatchApp(postReleaseReleaseManagement({payload: propagateRelease, env:"uat"}));        
          
          await dispatchApp(publishConfigurationReleaseManagement({id: workflowId , env:"uat",type:workFlowType})).then(unwrapResult).then((result)=>{ 
            if(result == true)
            {
              setShowSuccessNotif(true); setSuccessText("UAT Deployment completed")
            }
            else
            {
              setErrorText("UAT deployment failed, Invalid workflow"); setShowErrorNotif(true);
              const updatedEntity = {...devConfiguration,status:"New", environment: "uat" };
              dispatch(patchReleaseConfiguration({input: updatedEntity, env: "uat"}));
              dispatchApp(postConfigurationDetailsForRelease( { ... updatedEntity, env: "uat" }));
              dispatch(setReleaseStatus({version: actionedVersion, status: "New", env: "uat"}));
            } 
          });
          setuatApproveReject(true);
        }
      }
        else
        {
          setErrorText("You do not have permission to approve this workflow"); setShowErrorNotif(true);
        }
         }
        }>
        <Icon slot="start" name="accept"></Icon>
        Approve
      </Button>
      <Button appearance="command" disabled = {uatApproveReject || selectedReleaseIds.length != 1} 
      onClick={() => {
         if(approvePermUat.includes(subscriptionKey) || superAdmin )
         {
          
          const updatedConfiguration = {...devConfiguration, status:"New", environment: "uat" };
          dispatch(patchReleaseConfiguration({input: updatedConfiguration, env: "uat"}));
          dispatchApp(postConfigurationDetailsForRelease( { ...updatedConfiguration, env: "uat" }));          
          dispatch(setReleaseConfigurationStatus({status: "New", env:"uat"}));
          dispatch(setReleaseStatus({version: actionedVersion, status: "New", env: "uat"}));          
          setShowSuccessNotif(true); setSuccessText("Workflow Rejected");
          setuatDeploy(false);
         }
         else
         {
           setErrorText("You do not have permission to reject in UAT");  setShowErrorNotif(true);
         }
      }}>
        <Icon slot="start" name="calculatormultiply"></Icon>
        Reject
      </Button>

      
    </CommandBar>
      </div>
    </div>

  );
}
export const ReleaseConfigurationActionsUAT = withErrorBoundary("Release Actions UAT", ReleaseConfigurationActionsUATC);



