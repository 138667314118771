import { Skeleton } from "../../common/HarmonyEnablers"
import './loader-styles.css'

export const Loader: React.FC<{shape: 'rect' | 'circle', label: string, className?: string, fullPage?: boolean}> = ({shape, label, className, fullPage}) => {
    if (!className) {
        className = ''
    }
    return (
        <div className={`loader-wrapper ${fullPage ? 'loader-wrapper-fullpage' : ''} ${className}`}>
            <div>
                <span>{label}</span>
                <Skeleton
                    className='blue-loader-rect'
                    shape={shape}
                    shimmer
                />
            </div>
        </div>
    )
}