import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { UserDetails } from '../../model/user-details/UserDetails.model'

interface UserDetailsState {
 name : string
 username: string
 localAccountId: string
}

const initialState: UserDetailsState = {
  name : "",
  username: "",
  localAccountId: ""
}

export const userDetailsSlice = createSlice({
  name: 'userDetails',
  initialState,
  reducers: {
    setUserDetails (state, action:  PayloadAction<UserDetails>)  {
      state.name = action.payload.name
      state.username = action.payload.username
      state.localAccountId = action.payload.localAccountId
    }
  }
});

export const userDetailsReducer = userDetailsSlice.reducer;