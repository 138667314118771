import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RequestStatus } from "../../../model/ServiceRequestStatus.model"
import { getAllPredictions, getPredictions, getViewResultsCount, saveDatacartRecordsToFile } from "../../../services/SPAAdmin/CampaignDataCart.api";
import { Prediction } from "../../../model/preview-mergerule/Prediction.model";
import { Helper } from "../utils/Help";

export interface CampaignDataCartState {
    predictions: Prediction[],
    pageNum: number,
    maxPageNumber: number,
    pendingReview: boolean,
    softDelete: string[],
    requestStatus: RequestStatus,
    countStatus: RequestStatus,
    allpredictions: [Prediction[], Prediction[]],
    allPredictionsStatus: RequestStatus,
    viewResultsCount: {inCartCount: number, vCount: number},
    error: string | undefined,
    loading: boolean
}

const initialState: CampaignDataCartState = {
    predictions: [],
    maxPageNumber: 1,
    pageNum: 1,
    softDelete: [],
    pendingReview: false,
    allpredictions: [[], []],
    allPredictionsStatus: RequestStatus.idle,
    requestStatus: RequestStatus.idle,
    countStatus: RequestStatus.idle,
    viewResultsCount: {inCartCount: 0, vCount: 0},
    error: undefined,
    loading: false
}

export const CampaignDataCartSlice = createSlice({
    name: 'campaignDataCart',
    initialState,
    reducers: {
        setCartPageNum: (state, action: PayloadAction<number>) => {
            state.pageNum = action.payload;
        },
        setPendingReview: (state, action: PayloadAction<boolean>) => {
            state.pendingReview = action.payload;
        },
        setViewResultsCount: (state, action: PayloadAction<number>) => {
            const inCartCount = state.viewResultsCount.inCartCount + action.payload;
            state.viewResultsCount = {inCartCount: Math.max(inCartCount, 0) , vCount: 0}
        },
        resetCartStatus: (state, _: PayloadAction<void>) => {
            state.maxPageNumber = 1;
            state.pageNum = 1;
            state.requestStatus = RequestStatus.idle;
            state.error = undefined;
        },
        resetPageNumber: (state, action: PayloadAction<void>) => {
            state.maxPageNumber = 1;
            state.pageNum = 1;
        },
        updateSoftDelete: (state, action: PayloadAction<any[]|undefined>) => {
            if (action.payload && !Helper.isEmpty(action.payload)) {
                state.softDelete = state.softDelete.concat(action.payload.filter(row => !state.softDelete.includes(row)));
            } else {
                state.softDelete = [];
            }
        },
        showSpinner: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(getPredictions.pending, (state, _) => {
                state.requestStatus = RequestStatus.loading;
                state.error = undefined;
            })
            .addCase(getPredictions.fulfilled, (state, action) => {
                state.requestStatus = RequestStatus.succeeded;
                if (state.maxPageNumber < state.pageNum && (action.payload || []).length > 0) {
                    state.maxPageNumber = state.pageNum;
                    state.predictions = state.predictions.concat(action.payload);
                } else if (state.pageNum === 1) {
                    state.predictions = action.payload;
                }
                state.error = undefined;
            })
            .addCase(getPredictions.rejected, (state, action: any) => {
                state.requestStatus = RequestStatus.failed;
                state.error = "Get Datacart Records: " + (action.payload['Message'] || action.error.message);
            })
            .addCase(getViewResultsCount.pending, (state, _) => {
                state.countStatus = RequestStatus.loading;
                state.viewResultsCount = {inCartCount: 0, vCount: 0};
                state.error = undefined;
            })
            .addCase(getViewResultsCount.fulfilled, (state, action) => {
                state.countStatus = RequestStatus.succeeded;
                state.viewResultsCount = action.payload
            })
            .addCase(getViewResultsCount.rejected, (state, action: any) => {
                state.countStatus = RequestStatus.failed;
                state.error = "Get ViewResults Count: " + (action.payload['Message'] || action.error.message);
            })
            .addCase(saveDatacartRecordsToFile.pending, (state, _: any) => {
                state.requestStatus = RequestStatus.loading;
                state.error = undefined;
            })
            .addCase(saveDatacartRecordsToFile.fulfilled, (state, action) => {
                if (action.payload?.Response === "ERROR") {
                    state.requestStatus = RequestStatus.failed;
                    state.error = "Download Template: " + action.payload?.Error;
                }
                else {
                    state.requestStatus = RequestStatus.succeeded;
                    state.error = undefined;
                }
            })
            .addCase(getAllPredictions.pending, (state, _) => {
                state.allPredictionsStatus = RequestStatus.loading;
                state.error = undefined;
            })
            .addCase(getAllPredictions.fulfilled, (state, action) => {
                state.allPredictionsStatus = RequestStatus.succeeded;
                state.allpredictions = action.payload;
                state.error = undefined;
            })
            .addCase(getAllPredictions.rejected, (state, action: any) => {
                state.allPredictionsStatus = RequestStatus.failed;
                state.error = "Get All Datacart Records: " + (action.payload['Message'] || action.error.message);
            })
    }
});

export const {
    setCartPageNum,
    setPendingReview,
    resetPageNumber,
    resetCartStatus,
    updateSoftDelete,
    setViewResultsCount,
    showSpinner
} = CampaignDataCartSlice.actions;

export const CampaignDataCartReducer = CampaignDataCartSlice.reducer