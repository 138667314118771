import { DataIngestion } from "../../workflows/DataIngestion.model"
import { Delta } from "../../workflows/Delta.model"
import { Processing } from "../../workflows/Processing.model"
import { PublishModel } from "../../workflows/Publish.model"

export class Config {
    public workflowId!: string
    public workflowName!: string
}

export class Attribute {
    public key!: string
    public value!: string
}

export class CampaignFilterQuery {
    public id!: string
    public name!: string
    public source!: string
    public SqlFilter!: string
    public jsonQueryStringFilter!: string
    public filterToDisplay!: string
}

export class CampaignPostProcessing {
    public stepID!: string
    public type!: string
    public outputViewName!: string
    public path!: string
    public appModelName!: string
    public query!: string
    public connectionId!: string
}

export class ReviewList {
    public userIds!: Array<string>
    public attributes!: Array<Attribute>
    public list!: Array<string>
}

export enum CampaignRunTypes
{
    ADB_RUN_SUCCESSFUL = 1,
    FILE_PROCESSED = 2
}
export enum PreSummaryStatus
{
    IN_PROGRESS = "In Progress",
    PUBLISHED = "Published"
}

export class PostDeployInfo
{
    type?: CampaignRunTypes
    timeStamp?: string
    error?: string
}
export class PreSummary
{
    suppression?: Suppression
}
export class Suppression
{
    status?: string
    adbRunId?: string
    modifiedDate?: string
}
export class CampaignAuditHistoryModel {
    public id!: string
    public fieldName?:string
    public oldValue?:string
    public newValue?:string
    public modifiedBy?: string
    public modifiedDate!: string
    public sourceId!: string
    public referenceId!: string
    public workflowId!: string
    public version!: number
}
export class Campaign {
    public id!: string
    public partitionKey!: string
    public subscriptionKey!: string
    public type!: string
    public status!: string
    public data?: string
    public version!: number
    public environment!: string
    public config!: Config
    public createdBy!: string
    public modifiedBy!: string
    public modifiedDate!: string
    public family!: string
    public startOnDate!: string
    public expiryDate!: string
    public actionByDate!: string
    public workFlowType!: string
    public selfServeVersion?: string
    public tenantName!: string
    public isActionPublish!: boolean
    public description?: string
    public tags!: string
    public typeOfMerge!: string
    public defaultMergeRule!: string
    public jobId?: string
    public delta!: Array<Delta>
    public publish!: PublishModel[]
    public summary!: Array<Attribute>
    public filters!: Array<CampaignFilterQuery>
    public inCart!: Array<string>
    public outOfCart!: Array<string>
    public reviewList!: Array<ReviewList>
    public postDeployInfo?: Array<PostDeployInfo>

    public dataSets!:Array<string>
    public dataIngestion!: DataIngestion[]
    public preProcessing!: Processing[]
    public preSummary!:PreSummary
    // public Campaign() {
    //     this.isActionPublish = false;
    //     this.delta = [];
    //     this.publish = [];
    //     this.postProcessing = [];
    //     this.summary = [];
    //     this.filters = [];
    //     this.inCart = [];
    //     this.outOfCart = [];
    // }
}
export class DataCartRowCustomData {
    public id!:string
    public sourceId!:string
    public referenceId!:string
}