import React, { useEffect } from 'react';
import { useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { withErrorBoundary } from "../../../shared-components/ErrorBoundary";
import { Button, Card, Icon, DataGrid, Pagination, Toggle, Dialog, MessageBar, ProgressRing, TextField } from "../SpaAdminScope";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../root-redux/RootState";
import { campaignCartListSelector } from "../redux/CampaignCartList.Selector.redux";
import { DataCartFilter } from "./Components/DataCartFilter";
import { createScope, dataGrid, partnerCenterTheme, tooltip } from "@harmony/enablers/react";
import { addToReviewList, clearReviewList, deleteFromReviewList, resolveReviewList, setInCartAndOutOfCart } from "../redux/CampaignById.redux";
import { postCampaignDetails, postCampaignInSilent } from "../../../services/SPAAdmin/CampaignById.api";
import { getAllPredictions, getPredictions, saveDatacartRecordsToFile, updateDataCartField } from "../../../services/SPAAdmin/CampaignDataCart.api";
import { resetPageNumber, setCartPageNum, setPendingReview, setViewResultsCount, showSpinner, updateSoftDelete } from "../redux/CampaignDataCart.redux";
import { Helper } from "../utils/Help";
import { ATTRIBUTE_REFERENCE_ID_KEY, CAMPAIGN_AUDIT_COLUMN_SOURCEID, CAMPAIGN_AUDIT_ESTIMATEDVALUE_FIELD, CampaignAreaReviewerMailConfig, DATACART_COLUMN_STATUS, ICampaignMailConfigParams, MessageTypes } from "../SPAConstants";
import { RequestStatus } from "../../../model/ServiceRequestStatus.model";
import { sendAreaReviewerNotification } from "../../../services/SPAAdmin/MEONotification.api";
import { postRelease } from "../../../services/PostRelease.api";
import { addNewWorkflow } from "../../workflows/workflow-by-id-redux/WorkflowById.actions.redux";
import { Environment, Release } from "../../../model/releases/Release.model";
import { setDatasetCache, UserMode } from "../redux/CacheHandler.redux";
import { FileUploadPopUp } from "../dataset/FileUploadPopup";
import { DatasetFileUploadModel, DatasetResponseType } from "../../../model/workflows/DatasetFileUpload.model";
import { setCurrentDatasetFileUploadState } from "../../workflows/workflows-redux/DatasetFileUpload.redux";
import { CampaignAuditHistoryModel, DataCartRowCustomData } from '../../../model/SPAModels/campaigns/campaign.Model';

interface IDataCart {
    nextPageEvent: (idx: number) => void
}

const DataCartC: React.FC<IDataCart> = ({nextPageEvent}) => {

    const scope = createScope({
        theme: partnerCenterTheme,
        reactInstance: React,
      });
    const Tooltip = scope.forReact(tooltip);
    const dispatchApp = useDispatch<AppDispatch>();
    const [selectedRows, setSelectedRows] = useState<string[]>([]);
    const [isAcceptDialogOpen, setIsAcceptDialogOpen] = useState<boolean>(false);
    const [isDeclineDialogOpen, setIsDeclineDialogOpen] = useState<boolean>(false);
    const [isDeleteAllOpen, setIsDeleteAllOpen] = useState<boolean>(false);
    const [isShareOpen, setIsShareOpen] = useState<boolean>(false);
    const [isDeleteDisabled, setIsDeleteDisabled] = useState<boolean>(true);
    const [message, setMessage] = useState<{type: MessageTypes, message: string, isOpen: boolean}|undefined>(undefined);
    const [openCreateNewDataset, setOpenCreateNewDataset] = useState(false);
    const [areaReviewerSG, setAreaReviewerSG] = useState<string>('aspaareareviewers@microsoft.com');

    const dataGridRef = useRef<dataGrid>(null);
    const campaignCartList = useSelector(campaignCartListSelector);
    const campaignById = useSelector((state: RootState) => state.campaignById);
    const campaignDataCart = useSelector((state: RootState) => state.campaignDataCart);
    const workflowsBySubKeys = useSelector((state: RootState) => state.workflowsBySubKeys);
    const userSalesRoles = useSelector((state: RootState) => state.userSalesRoles);
    const environment = useSelector((state: RootState) => state.env.selectedEnvItem);
    const [isAddDisabled, setIsAddDisabled] = useState<boolean>(userSalesRoles.isApprover);
    const filteredCampaign = useSelector((state: RootState) => state.filteredCampaign);

    const userDetails = useSelector((state: RootState) => state.userDetails);

    const handlePageChange = (e: any) => {
        dispatchApp(setCartPageNum(e.detail));
        if (e.detail % 10 === 0 && campaignDataCart.maxPageNumber < e.detail) {
            dispatchApp(getPredictions({}));
        }
    }

    useEffect(() => {
        let templateConfig: ICampaignMailConfigParams|undefined = undefined;
        const segment = (campaignById.summary["CampaignType"] || "").startsWith("Enterprise") ? "Enterprise" : 'SMC';
        const solutionArea = campaignById.summary["SolutionArea"];
        for(const key in CampaignAreaReviewerMailConfig) {
            const segmentConfig = CampaignAreaReviewerMailConfig[key]['Segment'], SolutionAreaConfig = CampaignAreaReviewerMailConfig[key]['SolutionArea'];
            if(segmentConfig === segment && SolutionAreaConfig === solutionArea) {
                templateConfig = CampaignAreaReviewerMailConfig[key];
            }
        };
        setAreaReviewerSG(templateConfig?.UserEmail || "aspaareareviewers@microsoft.com");
    }, [campaignById]);

    const sendSPACompleteNotificationCall = () => {
        dispatchApp(sendAreaReviewerNotification()).unwrap().then((result) => {
            if(result) {
                console.log(result);
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    const saveCampaign = () => {
        dispatchApp(resetPageNumber());
        dispatchApp(postCampaignDetails())
            .unwrap()
            .then(() => {
                setMessage({type: 'success', message: "Successfully Updated the campaign Information", isOpen: true});
                dispatchApp(getPredictions({}));
            });
    }

    const downloadDatacartRecords =  async () => {
        dispatchApp(getAllPredictions({}))
        .unwrap()
        .then(() => {
            dispatchApp(saveDatacartRecordsToFile(null));
        });
    }

    const isInvalidReviewerAddRecords = (srows: string[]|undefined = undefined) => {
        const rows = srows || selectedRows;
        if (userSalesRoles.isApprover && rows.length === 0) {
            return true;
        }
        let flag = false;
        rows.forEach(id => {
            const inCart = campaignById.campaign?.inCart.includes(id);
            const arList = campaignById.addReviewList.includes(id);
            const drList = campaignById.deleteReviewList.includes(id);
            if (arList || (inCart && !drList)) {
                flag = true;
            }
        });
        return flag;
    }

    const isInvalidReviewerDeleteRecords = (srows: string[]|undefined = undefined) => {
        const rows = srows || selectedRows;
        if (rows.length === 0) {
            return true;
        }
        let flag = false;
        if (userSalesRoles.isApprover) {
            rows.forEach(id => {
                const inCart = campaignById.campaign?.inCart.includes(id);
                const arList = campaignById.addReviewList.includes(id);
                const drList = campaignById.deleteReviewList.includes(id);
                if (drList || (!inCart && !arList)) {
                    flag = true;
                }
            });
        }
        return flag;
    }

    const onAddRecords = () => {
        if (!userSalesRoles.isApprover) {
            nextPageEvent(-1);
            return;
        }
        setIsAddDisabled(true);
        setIsDeleteDisabled(true);
        if (isInvalidReviewerAddRecords()) {
            setMessage({type: 'warning', message: "Invalid Selection: Cannot add record which already incart/Requested for addition!", isOpen: true})
        } else {
            selectedRows.forEach(id => dispatchApp(addToReviewList(id)));
            dispatchApp(setViewResultsCount(selectedRows.length));
        }
    }

    const onDeleteRecords = () => {
        setIsDeleteDisabled(true);
        if (userSalesRoles.isApprover && !isInvalidReviewerDeleteRecords()) {
            selectedRows.forEach(id => dispatchApp(deleteFromReviewList(id)));
            dispatchApp(setViewResultsCount(-1 * selectedRows.length));
            setIsAddDisabled(true);
            return;
        }
        dispatchApp(updateSoftDelete(selectedRows));
        const newInCart = (campaignById.campaign?.inCart || []).filter(id => !selectedRows.includes(id));
        dispatchApp(setInCartAndOutOfCart({
            InCart: newInCart,
            DataSets: (campaignById.campaign?.dataSets || []),
        }));
        dispatchApp(postCampaignInSilent());
        setIsAddDisabled(false);
    }

    const onDeleteAllRecords = () => {
        setIsDeleteAllOpen(false);
        dispatchApp(setInCartAndOutOfCart({
            InCart: [],
            DataSets: []
        }));
        dispatchApp(clearReviewList());
        saveCampaign();
    }

    const onAddAccounts = () => {
        const newWorkflowId = uuidv4().toString();
        const environments: Environment[] = [
          { environment: "dev", isDeployed: true },  { environment: "uat", isDeployed: true },  { environment: "prod", isDeployed: false }];
      
        const releaseData : Release = {
          environments: environments,
          timestamp: "",
          releaseId: uuidv4().toString(),
          workflowId: newWorkflowId,
          version: "1",
          id: uuidv4().toString(),
          type: "New Version",
          status: "New",
          scheduleCreated: false,
        };
        dispatchApp(postRelease({payload : releaseData, env: environment}));
        dispatchApp(addNewWorkflow({workflowId: newWorkflowId, subscriptionKey: "3A2504E0-4F89-11D3-9A0C-0305E82C3302", environment, modifiedBy: "", workflowType: "Dataset"}))
        dispatchApp(setCurrentDatasetFileUploadState({fileUploadObj :{} as DatasetFileUploadModel, responseType: DatasetResponseType.Idle}));
        dispatchApp(setDatasetCache({
            datasetId: undefined,
            mode: UserMode.CREATE
        }));
        setOpenCreateNewDataset(true);
        if(!openCreateNewDataset)
            dispatchApp(resetPageNumber());
    }

    const onRowSelect = (e: any) => {
        const rows = (dataGridRef.current!.selectedRows || []).map(detail => (detail.customData as DataCartRowCustomData).id as string);
        setSelectedRows(rows);
        setIsAddDisabled(isInvalidReviewerAddRecords(rows));
        setIsDeleteDisabled(isInvalidReviewerDeleteRecords(rows));
    }

    const handlePendingReview = () => {
        dispatchApp(setPendingReview(!campaignDataCart.pendingReview));
        dispatchApp(getPredictions({}));
    }

    const acceptReviewItems = () => {
        setIsAcceptDialogOpen(false);
        dispatchApp(resolveReviewList(selectedRows));
        saveCampaign();
        dispatchApp(setPendingReview(false));
    }

    const declineReviewItems = () => {
        setIsDeclineDialogOpen(false);
        dispatchApp(clearReviewList(selectedRows));
        saveCampaign();
        dispatchApp(setPendingReview(false));
    }
    const updateEstimatedValue = (value:any, id:string) => {
        if(value === "") {
            setMessage({type: 'error', message: "Estimated value cannot be blank", isOpen: true})
            dispatchApp(getPredictions({}));
            return;
        } else if(isNaN(value) || value <= 0) {
            setMessage({type: 'error', message: "Estimated value should be a number and greater than 0", isOpen: true})
            dispatchApp(getPredictions({}));
            return;
        } 
        var campaignAuditHistory:CampaignAuditHistoryModel = new CampaignAuditHistoryModel();
        campaignCartList.rows.filter(row => row.id === parseInt(id)).map(row => {
        campaignAuditHistory.id =  uuidv4().toString();
        campaignAuditHistory.oldValue = row.cells[CAMPAIGN_AUDIT_ESTIMATEDVALUE_FIELD]
        campaignAuditHistory.newValue = value;
        campaignAuditHistory.fieldName = CAMPAIGN_AUDIT_ESTIMATEDVALUE_FIELD;
        campaignAuditHistory.modifiedBy = userDetails?.localAccountId;
        campaignAuditHistory.sourceId = (row.customData as DataCartRowCustomData).sourceId;
        campaignAuditHistory.referenceId = (row.customData as DataCartRowCustomData).referenceId;
        campaignAuditHistory.workflowId = campaignById.campaign?.config.workflowId??"";
        campaignAuditHistory.version = campaignById.campaign?.version??0;

    });
    dispatchApp(updateDataCartField(campaignAuditHistory)).unwrap()
    .then((response) => { 
        if(response) {
        dispatchApp(getPredictions({}));
    }
    else{
        setMessage({type: 'error', message: "Error while updating the estimated value", isOpen: true})
        dispatchApp(getPredictions({}));
    }
    });
        
    }
    return (
        <>
            {
                message?.isOpen &&
                <MessageBar appearance={message.type} open={message.isOpen} onHeHide={() => setMessage(undefined)}>
                    {message.message}
                </MessageBar>
            }
            <Card className='spa-query-card'>
            {
                (userSalesRoles.requestStatus === RequestStatus.loading || campaignById.requestStatus === RequestStatus.loading )
                 ?
                <div className='spa-progressring'>
                    <ProgressRing label="Loading..." indeterminate></ProgressRing>
                </div>
                :
                <>
                <span slot="heading">
                    <div className="spa-query-card-command">
                        <Button appearance="command" onClick={onAddRecords} disabled={campaignDataCart.pendingReview || isAddDisabled}>
                            <Icon slot="start" name="add"></Icon>Add Records
                        </Button>
                        <Button appearance="command" onClick={onDeleteRecords} disabled={campaignDataCart.pendingReview || isDeleteDisabled}>
                            <Icon slot="start" name="cancel"></Icon>Delete Records
                        </Button>
                        {!userSalesRoles.isApprover &&
                            <Button appearance="command" onClick={() => setIsDeleteAllOpen(true)} disabled={campaignDataCart.pendingReview || !isDeleteDisabled || (campaignById.campaign?.inCart || []).length === 0}>
                                <Icon slot="start" name="delete"></Icon>Delete all Records
                            </Button>
                        }
                        {userSalesRoles.isApprover ?
                            <Button appearance="command" onClick={saveCampaign} disabled={!campaignById.unsavedChanges}>
                                <Icon slot="start" name="save"></Icon>Save changes
                            </Button>
                            :
                            (campaignById.campaign?.reviewList || []).some(rList => rList.list.length > 0) && 
                            <>
                                <Button appearance="command" onClick={() => setIsAcceptDialogOpen(true)} disabled={!campaignDataCart.pendingReview || campaignCartList.loading}>
                                    <Icon slot="start" name="save"></Icon>Accept
                                </Button>
                                <Button appearance="command" onClick={() => setIsDeclineDialogOpen(true)} disabled={!campaignDataCart.pendingReview || campaignCartList.loading}>
                                    <Icon slot="start" name="disconnectdevice"></Icon>Decline
                                </Button>
                                <Toggle onHeChange={() => handlePendingReview()} checked={campaignDataCart.pendingReview} disabled={campaignCartList.loading}>
                                    <span slot="checked-message">Pending Review ({campaignById.addReviewList.length + campaignById.deleteReviewList.length})</span>
                                    <span slot="unchecked-message">Pending Review ({campaignById.addReviewList.length + campaignById.deleteReviewList.length})</span>
                                </Toggle>
                            </>
                        }
                        <DataCartFilter/>
                        <Button appearance="command"  onClick={downloadDatacartRecords}>
                            <Icon slot="start" name="download"></Icon>Download Records
                        </Button>
                        {(filteredCampaign?.status === RequestStatus.loading || campaignDataCart?.requestStatus === RequestStatus.loading) && 
                            <div style={{marginLeft: '10px', marginTop: '12px'}}>
                            <ProgressRing labelPlacement="end" label="Downloading records..." indeterminate></ProgressRing>;
                            </div>
                        }
                        { 
                            userSalesRoles.isApprover &&
                            <Button id="add-accounts" appearance="command" onClick={onAddAccounts}>
                                <Icon slot="start" name="add"></Icon>Add Accounts
                                <Tooltip anchor="add-accounts" fixedPlacement={true} placement='bottom'>
                                    Add Accounts - This process may take around 5 minutes.
                                </Tooltip>
                            </Button>
                        }
                        {
                            userSalesRoles.isApprover ?
                            <p style={{ marginLeft: 'auto', color: 'silver', top: '10px', fontSize: '13px', position: 'relative'}}>(Rows: {campaignCartList.rows.length})</p>
                            :
                            <Button appearance="command" style={{ marginLeft: 'auto', position: 'relative'}} onClick={() => setIsShareOpen(true)}>
                                <Icon slot="start" name="share"></Icon>Share
                            </Button>
                        }
                    </div>
                </span>

                <div>
                    <DataGrid
                        rows={campaignCartList.rows}
                        columns={campaignCartList.columns}
                        select="multiple"
                        loading={campaignCartList.loading || Helper.isLoadingStatus(campaignById.requestStatus, workflowsBySubKeys.status)}
                        onHeRowSelectChange={onRowSelect}
                        onHeSelectAllChange={onRowSelect}
                        onHeBulkSelect={onRowSelect}
                        ref={dataGridRef}
                    >
                        <span slot="no-records">No records were found.</span>
                        {campaignCartList.rows.map(row => {
                            // let color = 'silver';
                            // if (row.cells['Status'] === 'Added') {
                            //     color = '#00cb00';
                            // } else if (row.cells['Status'] === 'Deleted') {
                            //     color = 'red'; 
                            // }
                            // if (userSalesRoles.isApprover || cartState.pendingReview) {
                            //     return <Icon slot={`Status-${row.id}`} name='statuscircleinner' style={{paddingLeft: '10px', color: `${color}`}}></Icon>
                            // }
                            return <>
                            {
                                
                                    <TextField slot={`EstimatedValue-${row.id}`} value={row.cells["EstimatedValue"]} 
                                    disabled={!(userSalesRoles.isSpaAdmin || userSalesRoles.isSpaUser)}
                                    onHeChange={(e) => {
                                        updateEstimatedValue((e.currentTarget as HTMLInputElement).value, row.id);
                                    }}/>
                                    
                            }
                            </>
                            
                        })}  
                    </DataGrid>
                    <Pagination
                        slot="pagination"
                        value={campaignDataCart.pageNum}
                        pageCount={campaignCartList.totalPage}
                        onHePageChange={handlePageChange}
                    />
                </div>
                </>
            }
            </Card>
            

            <Dialog heading="Accept:" open={isAcceptDialogOpen} onHeAfterHide={() => setIsAcceptDialogOpen(false)}>
                <p>
                    Please click ok to accept the reviewed accounts<br />
                </p>
                <Button slot="footer" appearance="primary" onClick={acceptReviewItems}>Ok</Button>
                <Button slot="footer" onClick={() => setIsAcceptDialogOpen(false)}>Close</Button>
            </Dialog>

            <Dialog heading="Decline:" open={isDeclineDialogOpen} onHeAfterHide={() => setIsDeclineDialogOpen(false)}>
                <p>
                    Would you like to decline accounts that are pending for review?<br />
                </p>
                <Button slot="footer" appearance="primary" onClick={declineReviewItems}>Yes</Button>
                <Button slot="footer" onClick={() => setIsDeclineDialogOpen(false)}>No</Button>
            </Dialog>

            <Dialog heading="Share:" open={isShareOpen} onHeAfterHide={() => setIsShareOpen(false)}>
                <p>
                    Please click <b>Ok</b> to send a review request email to Area reviewers :<br/>
                    <Button appearance="link">{areaReviewerSG}</Button>
                </p>
                <Button slot="footer" appearance="primary" onClick={() => {
                    sendSPACompleteNotificationCall();
                    setMessage({type: 'success', message: 'Mail sent', isOpen: true});
                    setIsShareOpen(false);
                }}>Ok</Button>
                <Button slot="footer" onClick={() => setIsShareOpen(false)}>Cancel</Button>
            </Dialog>

            <Dialog heading="Delete All:" open={isDeleteAllOpen} onHeAfterHide={() => setIsDeleteAllOpen(false)}>
                <p>
                    You are attempting to delete all the <b>{(campaignById.campaign?.inCart||[]).length}</b> accounts from Data cart<br />
                </p>
                <Button slot="footer" appearance="secondary" onClick={onDeleteAllRecords} style={{backgroundColor: '#dc3545', color: 'white'}}>Delete All ({(campaignById.campaign?.inCart||[]).length})</Button>
                <Button slot="footer" onClick={() => setIsDeleteAllOpen(false)}>No</Button>
            </Dialog>

            {openCreateNewDataset && (
                <FileUploadPopUp openCreateNewDataset={openCreateNewDataset} setOpenCreateNewDataset={setOpenCreateNewDataset} areaIfApprover={userSalesRoles.isApprover?userSalesRoles.userDetails.area:""}/>
            )} 
        </>
    )
};

export const DataCart = withErrorBoundary('Campaign: DataCart', DataCartC);