import React from "react";
import { createScope, partnerCenterTheme } from "@harmony/enablers/react";
import {
  dropdown,
  filterPill
} from '@harmony/enablers/react';

const scope = createScope({
  reactInstance: React,
  styles: [partnerCenterTheme]
});


export const Dropdown = scope.forReact(dropdown);
export const FilterPill = scope.forReact(filterPill);